import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import useSiteMetadata from '../components/SiteMetadata'
import { Link } from '@reach/router'

export const WroksPageTemplate = ({ content, contentComponent, helmet, title, description}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div style={{ paddingBottom: '0.1em', fontSize: '1.0em', fontWeight: 'bold'}}>{title}</div>
            <div style={{ paddingBottom: '0.75em',whiteSpace: 'pre-wrap'}}>{description}</div>
            <PostContent content={content} />
            <div className="content-footer columns is-mobile section">
              <div className="content-footer__list column">
                <Link to="/about?from=top">Back to list</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

WroksPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const WorksPage = ({ data, location }) => {
  const { markdownRemark: post } = data
  const { siteUrl } = useSiteMetadata()
  const mode = location.search.indexOf('from=top') !== -1 ? 'simple' : ''

  const HeaderBackLink =
    mode === 'simple'
      ? () => (
          <Link to="/">
            <div className="header__back--simple">Gosuke Kambayashi / plat</div>
          </Link>
        )
      : () => ''

  return (
    <Layout mode={mode}>
      <HeaderBackLink />
      <WroksPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | plat">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              property="og:title"
              content={`${post.frontmatter.title} | plat`}
            />
            <meta
              property="og:image"
              content={`${siteUrl}${post.frontmatter.featuredimage.childImageSharp.fluid.src}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

WorksPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default WorksPage

export const pageQuery = graphql`
  query WorksPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
